<script setup lang="ts">
import {router, useForm, usePage} from '@inertiajs/vue3';
import {onMounted, ref, Ref} from "vue";
import {useWebApp} from "vue-tg";

const form = useForm({
    queryString: ''
});

const submit = () => {
    form.post(route('telegram-login.auth'), {
        onFinish: () => {
            console.log('Submitted');
        },
        onError: () => {
            console.log('Error');
        },
        onSuccess: () => {
            //router.visit(route('lobby'))
            console.log('Success');
        },
    });
};

onMounted(async () => {

    try {
        if (usePage().props.auth.user?.id != undefined) {
            router.visit(route('game'))
            return;
        }

        const {initData} = useWebApp()

        form.queryString = initData;
        console.log('Init data: '+ initData)
        submit()
    } catch (error) {
        console.log('Error during onMounted:', error)
    }
});
</script>

<template>
    <div>
        <div v-if="form.queryString == ''">
            This page is only visitable through telegram.
        </div>
    </div>
</template>

<style scoped>

</style>
